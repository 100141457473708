.btn-dark {
  color: #fff;
  background-color: #d8345f;
  border-color: #d8345f;
}

.btn-dark:hover {
  color: #fff;
  background-color: #ba2b50;
  border-color: #ba2b50;
}

.btn-light {
  color: #a13f58;
}

.btn-light:hover {
  color: #fff;
  background-color: #ba2b50;
}

.btn-light.btn-dark {
  color: #fff;
}